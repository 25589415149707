import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:AppleSystemUIFont;\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n@font-face\n\t{font-family:"Noto Sans Symbols";\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-size:11.0pt;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n',
        }}
      />
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "24.0pt", lineHeight: "130%" }}>
              Privacy Policy
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Last revised on the 25th of April, 2024
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            This Privacy policy may change over time. Whenever we have planned
            to apply material changes to this policy, we will notify you in time
            so as to review the changes.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            No key changes in this version.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are reading the most recent version of Join’s Privacy Policy.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Responsible for your information under this Privacy Policy (the
            “data controller”) is Join{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Join is an App that functions as a personal tool for your overall
            real-life socializing. It, therefore, goes without dispute that in
            order to provide you with services such as creating new friendships
            and helping you get socially active in a real setting, with
            interesting and relatable-to-you people, we need to collect some
            information about you. The more an explicit description of your
            profile you give us, the better your chances of experiencing the
            full potential of Join. Some{" "}
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            information is required for our services to work. Other information
            is optional, but without it, the quality of your experience could be
            affected.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              1. Application of our Privacy Policy
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            This privacy policy is applicable to all apps, events, websites,
            systems or any other services employed under the Join brand. The
            aforementioned will be referred to by the term “services” for
            reasons of convenience. On these services, as a user you may send
            messages, take and send photos or videos, create Groups at a
            hospitality business or an Event affiliated with Join, create custom
            events and much more. We will call the things you can do using the
            services “activity”.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              2. Why Join collects data
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.1 To provide, customize and improve our products
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We use data to provide you with an overall personalized experience
            and to improve our services. Examples would constitute the matching
            profile recommendations you receive or combined recommendations of
            places or events and users and to name a few.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.2 To administer your account
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We use data to create and manage your personal account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.3&nbsp; To combat fraud and ensure safety and integrity
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We use data to verify the validity of accounts and investigate
            suspicious activity, substantially respond to claims of misconduct
            or unauthorized use of the services, to deal with spam or detect
            when someone is in need of help or be enabled to enforce the law or
            exercise our rights.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.4 To communicate with you
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            In order to communicate with you, we use information you have
            provided us with regarding your profile.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.5 Innovating for social good
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We would be unable to fulfill our number one goal that set the
            fundamentals on which Join is developed: to provide you with a tool
            that would ameliorate your overall actual, social life and therefore
            provide for the social good if we weren’t collecting any data from
            our members.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.6 To include advertising services and personalized content in
              the App
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We may use data to operate targeted and personalized advertising and
            promotions, instead of impersonal and random. We want to be able to
            inform you about products, services, hospitality places or events
            that may interest you. For example, we collect data from your
            profile and activity relevant to the App in order to be able to
            provide you with personalized content in the future. For instance,
            we may detect that you prefer a certain type of hospitality
            business, like Night Clubs. We want to be able to prioritize Night
            Clubs as a type of places results you see. Join will ask for your
            explicit permission to track your activity across other companies’,
            apps and websites. You can alter this permission preference through
            your devices{" "}
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            System Settings.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              2.7 To comply with applicable laws and regulations
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We use data to adhere to our obligations of engaging with
            regulators, such as the Data Protection Commission under the General
            Data Protection Regulation and the Data Protection Act 2018 or to
            comply with corporate and taxation regulations.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              3. Legal Basis
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We rely upon different legal bases to store and process your
            information for the purposes analyzed in the above section of this
            Privacy Policy. We might rely upon different legal bases when it
            comes to processing the same information, depending on the
            circumstances. We outline these legal bases below:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              3.1 Provision of our service to you
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            The reason we process your data for purposes 2.1, 2.2 is to perform
            our contract with you. More explicitly, we need your information so
            that we can support the creation of your account and profile, make
            it viewable to other users and to ultimately provide our features
            and services by means of this App.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              3.2 Consent{" "}
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We process our information provided that you have given us your
            consent. In cases where you might choose to provide us with
            “sensitive” or “special” as per-certain-jurisdictions information
            such as your dating status, you are consenting to our collection and
            processing of that information. Join will not directly publish any
            such sensitive information about you and will only use it in order
            to ameliorate your personal experience using the App. In the above
            instance, we may use your dating status in order to recommend to you
            profiles that match your dating status. If you wish you may always
            withdraw your consent by removing such sensitive-regarded data by{" "}
            <b>
              navigating to Your Profile Page and tapping on the “Edit Profile”
              button
            </b>
            . In other instances, you may also withdraw such granted permission
            for sensitive data use via your device settings.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              3.3 Legitimate Interests
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We process your information based on our or others' legitimate
            interests. For example we may process the behaviour and preferences
            of members with the aim of constantly ameliorating the services we
            provide. Our interests overall would entail providing a
            personalized, safe, pioneering and profitable App and we process
            data where necessary to enforce our rights, support law enforcement
            and protect ourselves during legal action.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              3.4 Legal Obligation
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We process your information and data in order to comply with legal
            obligation, applicable laws and regulation, if requested for
            governmental investigation, or needed in relation to a legal claim,
            complaint, litigation or regulatory proceedings.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              4. Data we collect{" "}
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              4.1 Information you give Join
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Your activity on Join, such as which features you have been using,
            when you logged in, actions taken within the App, interactions with
            other users.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            All information you provide Join when you create your profile or
            after, including but not limited to i.e. your email address, name,
            age, gender, photos, hobbies and interests, your bio and more
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Messages you send and receive, including their content, subject to
            applicable law. We cannot collect end-to-end encrypted messages
            unless members report them to us for review content that you place
            on the service, view or interact with, or is otherwise related with
            Join
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            The time and duration of your activities on our services and the
            frequency of using them
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Your responses when participating in surveys, questionnaires, or
            market studies regardless whether related to Join or third parties
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Views of and all types of interactions with uploaded Groups and
            Events and their content
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Information you disclose to our customer service team
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            To share certain content, like pictures or videos, or voice messages
            you grant us access your camera or camera roll or microphone
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              4.2 Information we receive from others
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We collect or infer information about you based on the activity of
            other members, i.e. when other members send you a message, friend
            request, join request or invitation, when other users like a group
            you’re a member of or add you to their close friend list and more.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You may decide to upload or otherwise place information about you on
            join using a third-party means to do so, such as logging in with
            Facebook. We may collect such relevant information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We may receive and collect information about you via surveys,
            questionnaires or ad campaigns on other platforms or in
            collaboration with third parties. Provided we are legally permitted
            to do so, third parties may give us access to information regarding
            suspected or convicted bad actors with the aim of ensuring the
            safety and security of our community.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              4.3 Information we collect from your activity, using the services
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Information about friends, users, accounts, Groups or Events or
            communities that you're connected to or interact with and the join
            requests and invitations you send or receive and the people you
            send/ receive them to/ from.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Which profiles you interact the most or you are a “social match”
            with
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Information regarding the time you spend using different features of
            the app and the capturing of screenshots
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Information about your search history{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Information about your device(s) including hardware and software
            information such as the IP address of the device used, the device ID
            and type, app crashes, apps’ settings or other information such as
            whether the app is in the foreground
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Information you’ve granted us access to via your device settings
            such as your geolocation or access to your camera roll. The former
            may occur in the background, even when you’re not using the App,
            provided that you’ve granted Join such a permission. Please note
            that your geolocation data are not mandatory for any Feature to
            operate in the current version of our App, but we need it for
            analytical purposes and to support the safety of our users, Join
            being an App which is connected with the outside world and is based
            on the meeting of users in real environments.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "13.0pt",
              lineHeight: "130%",
              fontFamily: "AppleSystemUIFont",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              5. How we share information
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We primarily share your information with other users on the broad
            basis of fulfilling Join’s fundamental concept of bringing you
            closer to people. Additionally, we may also share some information
            with integrated affiliates/ partners or third parties that host
            their ads on our platform for example.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              5.1 Sharing with other members{" "}
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Whenever you place, disclose or otherwise make available information
            about your profile on the service, you automatically share that
            information with other users. That stated, you are sharing
            information at your own discretion and we’re hence strongly
            advocating that you are strictly limit what you share to content you
            are confident and comfortable sharing.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Explicit, “sensitive” or “special” information that you provide Join
            with is not published directly as part of your visible profile.
            Instead, Join processes such data so as to personalize the content
            you come across on the platform. Such data is your (i) Dating
            Preference, (ii) Birthday, (iii) Choice of social type you prefer in
            a friendship or relationship.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You can always limit the audience for part of your profile by
            activating Private Mode:{" "}
            <b>
              Navigate to Your Profile Page - Settings - Your Privacy and lastly
              toggle the switch to activate Private Mode
            </b>
            . More specifically, private profiles only disclose their full
            profile to their friends. A “non-friend” of a private profile cannot
            have access to the following, but not limited to, information
            regarding the latter:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Their joined Groups
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Their complete friends list
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Their university
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Their hobbies and interests
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Their photos, except their profile photo.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            In the event that a user has made a claim involving another user
            having infringed or violated their rights, we may communicate to the
            former, actions we may have taken in response to their claim.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              5.2 Sharing functionality via third-party means
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            As a Join user, your profile, a group that you’ve joined, an event
            you’ve created and more can be shared with people outside of our
            Service, by means of the sharing functionality supported by Join
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              5.3 Sharing with third parties
            </span>
          </b>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            {" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We may provide your information to external researchers, with the
            aim of their using it{" "}
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            to conduct meaningful research that promotes innovation, and to help
            Join promote safety, security and integrity throughout the services
            or share data with external service providers to help us operate and
            better our services by means of hosting data, analytics services,
            security services and more. We additionally may share your data with
            affiliates and partners who use our platform for advertising
            purposes or help us efficiently advertise on our platform.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              5.4 Corporate transactions
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Following actions in the nature of corporate reorganization such as{" "}
            <span style={{ background: "white" }}>
              merger, consolidation or other reorganization of Join, or a
              successor corporation or organization or bankruptcy or other
              change of ownership or control, we may transfer your information
              either in whole or in part provided we are involved.
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              5.5 Sharing with affiliates
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            We may share your information with affiliates such as the bars or
            clubs or cafeterias or all other businesses we are partners with for
            purposes of enhancing the service, enforcing our Agreement with you,
            assisting our affiliated businesses and promoting the safety and
            security of members and non-members online and in real life.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              5.6 When required or allowed by law
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Join may disclose your information when required by law in order to
            comply with a legal action or process, to enforce our agreement with
            you, to support the prevention of crime and the assistance of
            criminal justice or protection of potential victims, of our members,
            affiliates and ourselves. In other cases, Join might share
            information if doing so would alleviate or transfer our liabilities
            in a frivolous, litigious or actual lawsuit.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              6. How long do we store your Information for
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Join keeps i
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            nformation for as long as we determine it is needed so as to provide
            our services, comply with legal obligations or to protect our or
            other's interests or to fulfill any other legitimate operational
            purposes, as described is section 2, provided we are allowed to do
            so under applicable law.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              6.1 Termination of Account and Data
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Your data will not be deleted until 90 (ninety) days have passed
            from the day your account was terminated either by you or by us. In
            some instances, if legally permitted to so and by enforcing our
            agreement with you, we may maintain data for longer in order to
            disclose or use it to perform an investigation of unlawful,
            unauthorized or potentially harmful behaviors or to respond to legal
            requests, such as search warrants, court orders, production orders
            or subpoenas. This prolonged retention of data is justified on the
            bases of our legitimate interests and legal obligations. Please note
            that specific information, such as reports you’ve sent to us or
            messages you’ve sent won’t be fully deleted and the latter will
            remain visible to the recipients.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            In the event that you remain inactive for at least two years or
            more, we will automatically delete your account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              6.2&nbsp; Retention of limited/ special data
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We are storing limited/ special data for extended periods. More
            explicitly such data would be, if any, for example: evidence of the
            existence of suspended or past/ terminated accounts, transaction
            data, which we need to maintain for accounting, tax and other legal
            requirements as well as logs/ “traffic data”. We would do that in
            order to comply with legal data maintenance obligations, if
            requested for a governmental investigation, or if determined data to
            be involved in potential or confirmed violation of our terms and
            policies, or retain them on the basis of legal obligation or if it
            is otherwise required to do so in order to protect ourselves and to
            promote security, safety and integrity. <br />
            <br />
            Group-chats associated with Groups or Custom Events, along with the
            corresponding media exchanged within them, will undergo automatic
            deletion from our servers 14 (fourteen) days after the meeting date
            chosen by the creator of the respective group or custom event.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              7. Children
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join strictly prohibits anyone under the age of 18 years to create,
            use or maintain an account on the platform and is not to be held
            responsible in the event that someone who is not fulfilling these
            criteria creates, uses or maintains an account on Join.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              8. Recruiting
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join may collect, manage and analyze all personal information
            provided by potential job candidates, affiliates, agents,
            contractors or any other form of potential or established business
            relationship parties as part of our privacy policy in regard to
            recruitment and establishing partnerships. We may share such
            information with third-party service providers who have expertise in
            human resources or technical data operations.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              9. Your rights under GDPR or other relevant law
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              9.1 Access and correct your information
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You have the right to access and correct your information, subject
            to applicable law. You may do so from the settings section of Join.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              9.2 Withdraw consent
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You have the right to withdraw your consent that we process your
            information where needed to provide you with a functional feature or
            to serve other legitimate purposes of Join. If you choose to do so,
            this will not affect the lawfulness of your data’s processing before
            its withdrawal, based on previous consent. Your account will be
            terminated and your data will be erased. Unfortunately, Join relies
            on at least some information of you to deliver its functionalities,
            and hence the withdrawal of your consent would render the use of
            Join infeasible.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            If you wish to withdraw your consent for us processing your data,
            please{" "}
            <b>
              navigate to your Profile Screen - Settings - Legal - Your Rights -
              Withdraw Data Processing Consent and follow the simple steps
            </b>
            . Note that this will terminate your Join account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You can always withdraw your consent or manage how Join collects
            certain data that are not necessary to deliver its core
            functionalities through your System Settings.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
              9.3 Data Portability
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are entitled to what is called “data portability” This means
            that you, as a user who provides your data to Join, have the right
            to transmit it to a third party or business without any
            interference. You may request us to send a file of your data to your
            email address which you can download and transmit freely, provided
            that{" "}
            <span style={{ background: "white" }}>
              this action would not be in violation of a law or court order.
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              9.4 Download of information
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            If you wish to download your collected data,
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            please{" "}
            <b>
              navigate to your Profile Screen - Settings - Legal - Your Rights -
              Download your Data and follow the simple steps
            </b>
            <span style={{ background: "white" }}>
              . Within three days, we will generate a file of your collected
              data and deliver it to your email address for you to download.{" "}
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              9.5 Deletion of Data
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You may exercise your right to have us erase your data, subject to
            applicable law.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Ιf you wish to delete your
            <span style={{ background: "white" }}>
              {" "}
              activity data,{" "}
            </span>please{" "}
            <b>
              navigate to your Profile Screen - Settings - Legal - Your Rights -
              Delete Activity Data and follow the simple steps
            </b>
            <span style={{ background: "white" }}>
              . That includes but is not limited to: Photos, Messages, Voice
              Messages exchanged, Relations with other members, Groups or Events
              related to you and more.{" "}
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            If you wish to eras
            <span style={{ background: "white" }}>
              e all your information,{" "}
            </span>
            please{" "}
            <b>
              navigate to your Profile Screen - Settings - Legal - Your Rights -
              All Data Deletion and follow the simple steps
            </b>
            <span style={{ background: "white" }}>
              . This regards also information comprising your account and
              profile. Given our App’s incapability of delivering its features
              without certain user data your account must be terminated.{" "}
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Additionally, you may manually delete information regarding your
            profile that isn’t necessary for the App to deliver its Services,
            like your Hobbies or Interests falling under your personalized
            profile data. Wherever profile information erasure is possible and
            not prohibited by the system, you may delete it and Join will still
            function, although you may not experience its full potential of its
            services.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              Period until permanent Erasure
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            After you initiate the data erasure process, your information will
            not be deleted until a period of 90 (ninety) days has elapsed.
            However, in certain circumstances, we may need to retain your data
            for a longer period. This may be done in compliance with legal
            requirements or our agreement with you, and for the purposes of
            investigating unlawful, unauthorized, or potentially harmful
            behavior. In addition, we may also retain data to respond to legal
            requests, including search warrants, court orders, production
            orders, or subpoenas.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            The prolonged retention of data is justified by our legitimate
            interests and legal obligations.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="el"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              9.6 Object
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            In case you perceive that any material on Join infringes upon your
            personal legal rights or local laws, there is a possibility that it
            might also contravene our community guidelines, such as by
            exhibiting bullying, harassment, or hate speech.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            To alert us about such content, please{" "}
            <b>
              navigate to your Profile Screen - Settings - Report a Problem,
            </b>
            and report it as a violation of Joins Community Standards. We thank
            you for doing so in advance.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Additionally, you may use the linked report form to inform us if you
            believe that any data processing Join requests, breach your
            country's regulations or your personal rights as per the GDPR.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              9.7 Make an official complaint
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You also can lodge a complaint with Join’s lead supervisory
            authority, the Hellenic Data Protection Authority
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              10. How to contact us
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            If you have any questions about this Privacy Policy, please use this{" "}
          </span>
          <span lang="el">
            <a href="https://join-socialapp.com/contact">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                contact form
              </span>
            </a>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            .
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="el">&nbsp;</span>
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;

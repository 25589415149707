import { useTranslation } from "react-i18next";
import { getFaqs } from "../libraries/content/faqs";
import FaqItem from "./FaqItem";

export default function FAQ({ category }) {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const faqs = getFaqs(language, category);

  return (
    <div
      style={{
        height: "100%",
        marginTop: "90px",
        width: "80%", // Adjust this value based on your requirement
        margin: "0 auto", // This centers the content horizontally
        paddingTop: "150px",
        paddingBottom: "100px",
        color: "#57606f",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <span style={{ fontSize: "60px", fontWeight: 600 }}>FAQ</span>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {faqs?.map && faqs.map((faq) => <FaqItem key={faq.id} faq={faq} />)}
      </div>
    </div>
  );
}

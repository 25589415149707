import "../../assets/App.css";
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  StyledTextField,
  StyledButton,
  StyledSelect,
} from "../../components/StyledComponents";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import elot743Transliterate from "../../utils/greek_romanization";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ContactScreen() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { t } = useTranslation();

  const subjects = [
    t("AccountOrPrivacyIssue"),
    t("ReportInappropriateBehaviour"),
    t("UserComplaint"),
    t("BusinessEnquiry"),
    t("Other"),
  ];

  const canSubmit = [fullname, email, subject, message].every(Boolean);

  const handleFullnameChange = (event) => {
    setFullname(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const submitMessage = async (fullname, email, subject, message) => {
    setLoading(true);

    const headers = {
      Accept: "*/*",
    };
    await axios
      .post(
        "https://api.join-socialapp.com:3002/contact-us",
        { name: elot743Transliterate(fullname), email, message, subject },
        { headers },
      )
      .then((response) => {
        console.log(response);
        console.log(response.data);
        setShowSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        console.error("There was an error!", error);
      });

    setLoading(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="120vh"
      sx={{ background: "rgb(96, 163, 188)" }}
    >
      <Box
        width={600}
        height={760}
        margin={4}
        marginTop={20}
        marginBottom={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
        boxShadow="0.3em 0.3em 1em rgba(0, 0, 0, 0.3)"
        borderRadius={10}
        sx={{ background: "#ffffff" }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitMessage(fullname, email, subject, message);
          }}
        >
          <Stack spacing={2.5} alignItems="center">
            <Box
              sx={{
                fontSize: 32,
                color: "#666666",
                fontFamily: "NotoSans Regular",
                textAlign: "center",
              }}
            >
              {t("ContactUsTitle")}
            </Box>
            <Box
              sx={{
                maxWidth: 400,
                fontSize: 16,
                color: "#666666",
                fontFamily: "NotoSans Regular",
                textAlign: "center",
              }}
            >
              {t("ContactUsSubtitle")}
            </Box>
            <FormControl fullWidth variant="outlined">
              <StyledTextField
                label={t("FullName")}
                error={hasError}
                inputProps={{
                  maxLength: 40,
                }}
                value={fullname}
                id="fullname"
                variant="outlined"
                sx={{ width: 500 }}
                onChange={handleFullnameChange}
              />
              <FormHelperText id="fullname-helper">
                {fullname.length}/{40}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <StyledTextField
                label={t("Email")}
                error={hasError}
                inputProps={{
                  maxLength: 50,
                }}
                id="email"
                variant="outlined"
                onChange={handleEmailChange}
                sx={{ width: 500 }}
                value={email}
              />
              <FormHelperText id="email-helper">
                {email.length}/{50}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <StyledSelect
                displayEmpty
                labelId="select-subject"
                id="select-subject"
                value={subject}
                variant="outlined"
                sx={{ width: 500 }}
                onChange={handleSubjectChange}
              >
                <MenuItem value="">
                  <em>{t("Subject")}</em>
                </MenuItem>
                {subjects.map((desc) => (
                  <MenuItem value={desc} key={desc}>
                    {desc}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <StyledTextField
                label={t("Message")}
                error={hasError}
                inputProps={{
                  maxLength: 850,
                }}
                id="message"
                variant="outlined"
                multiline
                rows={6}
                onChange={handleMessageChange}
                sx={{ width: 500 }}
                value={message}
              />
              <FormHelperText id="message-helper">
                {message.length}/{850}
              </FormHelperText>
            </FormControl>
            {isLoading ? (
              <CircularProgress sx={{ color: "#1EABF1" }} />
            ) : (
              <StyledButton
                variant="contained"
                disabled={!canSubmit}
                type="submit"
                myColor="#1EABF1"
                hoverColor="#187DDA"
                sx={{ width: 500 }}
              >
                {t("SubmitButton")}
              </StyledButton>
            )}
          </Stack>
        </form>
        {/* In case of internal server error or communication fail the according warning is shown */}
        <Snackbar
          open={hasError}
          autoHideDuration={2000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: "100%" }}
          >
            An error occurred!
          </Alert>
        </Snackbar>
        <Snackbar
          open={showSuccess}
          autoHideDuration={2000}
          onClose={handleCloseSuccess}
        >
          <Alert
            onClose={handleCloseSuccess}
            severity="success"
            sx={{ width: "100%" }}
          >
            Message submitted successfully!
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default ContactScreen;

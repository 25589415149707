import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import CustomStyledLink from "./CustomStyledLink";
import { Stack } from "@mui/system";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MenuIcon from "@mui/icons-material/Menu";
import { StyledIconButton } from "./StyledComponents";
import useMediaQuery from "@mui/material/useMediaQuery";
import SideDrawer from "./SideDrawer";
import LanguageSelector from "./LanguageSelector";
import logo from "../assets/joinlogo.png";
import { useTranslation } from "react-i18next";
import titles from "../config/titles"; // Import titles configuration

export default function AppBarHeader() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const { t } = useTranslation();

  const lang = localStorage.getItem("lang") || "en"; // Get the current language
  const currentTitle = titles[lang].siteTitle; // Get the site title based on the current language
  const currentTagline = titles[lang].tagline; // Get the site tagline based on the current language

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    // Update document title
    const path = location.pathname.split("/").pop();
    document.title = titles[lang][path] || currentTitle;
  }, [location, lang, currentTitle, currentTagline]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          height: 90,
          background: "white",
          boxShadow: trigger ? 2 : "none",
        }}
      >
        <Toolbar
          sx={{
            height: 90,
            justifyContent: "space-between",
            color: "black",
            fontSize: 16,
            fontFamily: "OpenSans SemiBold",
          }}
        >
          {location.pathname !== "/" || trigger ? (
            <Link to="/" aria-label="Go to homepage">
              <img
                src={logo}
                alt="Join Trademark - Mobile app for socializing, joining events, meeting people, and dating"
                title="Trademark of Join - A Mobile App for Real Socializing, Events, and Dating"
                style={{ height: 30 }}
              />
            </Link>
          ) : (
            <Box />
          )}
          {matches ? (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ marginRight: { xs: 0, sm: 5 } }}
            >
              <LanguageSelector />
              <StyledIconButton
                aria-label="drawer"
                size="large"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon fontSize="inherit" />
              </StyledIconButton>
            </Stack>
          ) : (
            <Stack
              spacing={6}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ marginRight: { xs: 0, sm: 5 } }}
            >
              <LanguageSelector />
              <CustomStyledLink
                component={Link}
                to="/privacy_policy"
                underline="none"
              >
                {t("PrivacyPolicy")}
              </CustomStyledLink>
              <CustomStyledLink component={Link} to="/aboutus" underline="none">
                {t("AboutUs")}
              </CustomStyledLink>
            </Stack>
          )}
        </Toolbar>
      </AppBar>
      {matches ? (
        <SideDrawer isOpen={drawerOpen} setIsOpen={setDrawerOpen} />
      ) : null}
    </Box>
  );
}

import React from "react";

function CookiePolicy() {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:"Noto Sans Symbols";\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-size:11.0pt;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n',
        }}
      />
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "24.0pt", lineHeight: "130%" }}
            >
              Cookie Policy
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              Last revised on the 9th of April, 2024
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            This Cookie policy may change over time. Whenever we have planned to
            apply material changes to this policy, we will notify you in time so
            as to review the changes.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            No key changes in this version.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are reading the most recent version of Join’s Cookie Policy.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Please keep in mind that this Cookie Policy explains solely how we
            use Cookies and does not address how we process your data. Please
            read our{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/privacy_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Privacy Policy
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            if you are interested to learn how we process your personal
            information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              1. About Cookies and Similar Technologies
            </span>
          </b>
        </p>
        <div
          style={{ border: "none #D9D9E3 1.0pt", padding: "0cm 0cm 0cm 0cm" }}
        >
          <p
            className="MsoNormal"
            style={{
              marginBottom: "15.0pt",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingBottom: "0cm",
              borderBottom: "0cm none #D9D9E3",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              Cookies, small text files, allow us, along with our service
              providers and partners, to uniquely identify your device or
              browser for the various purposes outlined in the below paragraph.
              Typically, cookies assign a unique number to your device and are
              stored on your browser by the websites you visit, as well as
              third-party service providers and partners associated with those
              websites.{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "15.0pt",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingTop: "0cm",
              paddingBottom: "0cm",
              borderBottom: "0cm none #D9D9E3",
            }}
          >
            <b>
              <span
                lang="EN-US"
                style={{ fontSize: "12.0pt", lineHeight: "130%" }}
              >
                Session Cookies:
              </span>
            </b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              {" "}
              These cookies may persist on your computer or mobile device for
              varying durations. Some are "session cookies," active only while
              your browser is open and automatically deleted upon its closure.
              Session cookies lack the capacity to track your browser or device
              across different websites.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "15.0pt",
              marginRight: "0cm",
              marginBottom: "15.0pt",
              marginLeft: "0cm",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingTop: "0cm",
              paddingBottom: "0cm",
              borderBottom: "0cm none #D9D9E3",
            }}
          >
            <b>
              <span
                lang="EN-US"
                style={{ fontSize: "12.0pt", lineHeight: "130%" }}
              >
                Persistent Cookies:
              </span>
            </b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              {" "}
              In contrast, "persistent cookies" endure after your browser is
              closed, often programmed to automatically expire after a specified
              timeframe (e.g., days, weeks, or months). Upon returning to a
              website or visiting others that employ the same cookies, the
              website provider or its service providers can recognize the
              persistent cookies they've set, thereby identifying your browsing
              device.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "15.0pt",
              marginRight: "0cm",
              marginBottom: "15.0pt",
              marginLeft: "0cm",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingTop: "0cm",
              paddingBottom: "0cm",
              borderBottom: "0cm none #D9D9E3",
            }}
          >
            <b>
              <span
                lang="EN-US"
                style={{ fontSize: "12.0pt", lineHeight: "130%" }}
              >
                Application Programmable Interface (API):
              </span>
            </b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              {" "}
              An API serves as software facilitating communication between two
              or more applications, and we utilize them to establish
              communication between Join and our service providers.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "15.0pt",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingTop: "0cm",
              paddingBottom: "0cm",
              borderBottom: "0cm none #D9D9E3",
            }}
          >
            <b>
              <span
                lang="EN-US"
                style={{ fontSize: "12.0pt", lineHeight: "130%" }}
              >
                Software Development Kits (SDKs):
              </span>
            </b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              SDKs consist of code segments enabling the collection of data
              about your device, network, and interactions with a website.
              Similar to cookies, they generally operate by assigning a unique
              number to your device.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "15.0pt",
              marginRight: "0cm",
              marginBottom: "15.0pt",
              marginLeft: "0cm",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingTop: "0cm",
            }}
          >
            <b>
              <span
                lang="EN-US"
                style={{ fontSize: "12.0pt", lineHeight: "130%" }}
              >
                Local Storage:
              </span>
            </b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              Local storage, akin to cookies, involves files created by apps and
              websites to locally store information on your device. Although
              similar, local storage may possess slightly different
              characteristics, such as the ability to store more information and
              be located in a different space on your device than cookies. Local
              storage is typically used to enhance app and site functionality
              while remembering your preferences.
            </span>
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{ fontSize: "13.0pt", lineHeight: "130%", color: "#505965" }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              2. Why and How we use Cookies
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We only place Third-Party-Cookies which help us, like they also help
            other online service providers, to protect, provide and enhance the
            services we offer to you. We may achieve this in a variety of ways
            such as by{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            remembering your preferences for your next visits
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            providing analytics and data that is important for understanding how
            our users use our App, measuring which parts of the Service they
            enjoy using and which not, and thus providing us with substantial
            data indicating to us how to ameliorate the services we provide you
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            helping in regards to authentication i.e in respects like{" "}
            <span style={{ color: "#1C1E21", background: "white" }}>
              verifying your account and determining when you're logged in
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Assisting the security and integrity of the service. This would be
            achieved by helping us monitor alarming or suspicious behavior, in
            the form of someone trying to access an account by trying to guess
            its password or by assisting us with restoring your account in case
            you have forgotten your password
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;3. Partners using Cookies on Join and their Use
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "2.0pt" }}>
          <b>
            <u>
              <span
                lang="EN-US"
                style={{ fontSize: "15.0pt", lineHeight: "115%" }}
              >
                Analytics
              </span>
            </u>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "115%" }}
            >
              Google Analytics for Firebase
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "115%" }}
            >
              Categories of Data:
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            {" "}
            Device identifiers to collect and store information such as number
            of sessions per user, first launches, session information, app
            navigation, app activity, operating system, device models,
            geography, and app updates.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Privacy Policy:{" "}
          </span>
          <span lang="EN-US">
            <a href="https://policies.google.com/technologies/partner-sites">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://policies.google.com/technologies/partner-sites
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "2.0pt" }}>
          <b>
            <u>
              <span
                lang="EN-US"
                style={{ fontSize: "15.0pt", lineHeight: "115%" }}
              >
                Login &amp; Authenticate
              </span>
            </u>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "115%" }}
            >
              Apple
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "115%" }}
            >
              Categories of Data:
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            {" "}
            Login and authenticate event
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Privacy Policy:{" "}
          </span>
          <span lang="EN-US">
            <a href="https://www.apple.com/legal/privacy/en-ww/">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://www.apple.com/legal/privacy/en-ww/
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "115%" }}
            >
              Facebook
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "115%" }}
            >
              Categories of Data:
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            {" "}
            Login and authenticate event
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Privacy Policy:
          </span>
          <span lang="EN-US">
            <a href="https://en-gb.facebook.com/policy.php">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "windowtext",
                  textDecoration: "none",
                }}
              ></span>
            </a>
            <a href="https://en-gb.facebook.com/policy.php">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://en-gb.facebook.com/policy.php
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "115%" }}
            >
              Google
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "115%" }}
            >
              Categories of Data:
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            {" "}
            Login and authenticate event
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Privacy Policy:
          </span>
          <span lang="EN-US">
            <a href="https://policies.google.com/privacy?hl=en-US">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "windowtext",
                  textDecoration: "none",
                }}
              >
                {" "}
              </span>
            </a>
            <a href="https://policies.google.com/privacy?hl=en-US">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://policies.google.com/privacy?hl=en-US
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "2.0pt" }}>
          <b>
            <u>
              <span
                lang="EN-US"
                style={{ fontSize: "15.0pt", lineHeight: "115%" }}
              >
                Messaging
              </span>
            </u>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "115%" }}
            >
              Firebase
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "115%" }}
            >
              Categories of Data:
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            {" "}
            Messaging logs and identifiers
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Privacy Policy:
          </span>
          <span lang="EN-US">
            <a href="https://ably.com/privacy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "windowtext",
                  textDecoration: "none",
                }}
              ></span>
            </a>
            <a href="https://firebase.google.com/support/privacy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://firebase.google.com/support/privacy
              </span>
            </a>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              4. Ways of controlling cookies
            </span>
          </b>
        </p>
        <div
          style={{ border: "none #D9D9E3 1.0pt", padding: "0cm 0cm 0cm 0cm" }}
        >
          <p
            className="MsoNormal"
            style={{
              marginBottom: "15.0pt",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingBottom: "0cm",
              borderBottom: "0cm none #D9D9E3",
            }}
          >
            <b>
              <span
                lang="EN-US"
                style={{ fontSize: "12.0pt", lineHeight: "130%" }}
              >
                Opting out of partners
              </span>
            </b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              : Numerous partners we work with offer opt-out options on their
              respective websites. Details regarding this can be located in
              their individual policies, accessible through our Cookies Partner
              List, above.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "15.0pt",
              lineHeight: "130%",
              border: "none",
              padding: "0cm",
              paddingTop: "0cm",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              As previously stated, certain applications of these technologies
              are vital for the proper operation of the Platform, rendering them
              non-deactivatable.
            </span>
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              5. General
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You can opt out from of seeing online interest-based Ads by visiting
            the following links:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            <a href="http://www.aboutads.info/choices/">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "blue",
                }}
              >
                Digital Advertising Alliance
              </span>
            </a>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            &nbsp;in the US
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            <a href="http://youradchoices.ca/">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "blue",
                }}
              >
                Digital Advertising Alliance of Canada
              </span>
            </a>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            &nbsp;in Canada
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            <a href="http://www.youronlinechoices.eu/">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "blue",
                }}
              >
                European Interactive Digital Advertising Alliance
              </span>
            </a>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            &nbsp;in Europe
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Or by accessing your cookie-relevant device’s settings.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              6. How to contact us
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            If you have any questions about this Cookie Policy, please use this{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/contact">
              <b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    lineHeight: "130%",
                    color: "#1155CC",
                  }}
                >
                  contact form
                </span>
              </b>
            </a>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              .
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">&nbsp;</span>
        </p>
      </div>
    </>
  );
}

export default CookiePolicy;

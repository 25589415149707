function getFaqs(language, category) {
  if (!category) return faqs[language];

  const allFaqs = faqs[language];
  const filteredFaqs = allFaqs.filter((f) => f.categories.includes(category));
  return filteredFaqs;
}

const faqs = {
  en: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "I need help creating my group",
      answer: `
First, know that being your own group’s creator means you have absolute control over it, so there is no need for stress, even if it’s the first one you’re creating. Here’s a simple guide.

#### Date and Time
Here you are choosing the date and meeting time of your group. Don’t get confused with a.m. times: For example, Sunday, 00:45 may still be Saturday evening in your mind, but on Join it’s Sunday morning and that’s how you need to list it. 

#### Invite your Friends
This is the step where you initially invite your friends (don’t worry, you can invite more afterward). Invited members will receive an invitation notification from you, which they must affirmatively answer if they are to join the group. You can create a group without inviting anybody and have people request to join you. 

#### Make a Statement
This is your chance to add an element of personal touch to your group. This can be something in the likes of “We are celebrating my birthday” or “Girls wear a white dress”.

Your group is now ready for creation!
      `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "I need help creating my event",
      answer: `
First, know that being your own event’s creator means you have absolute control over it, so there is no need for stress, even if it’s the first one you’re creating. Here’s a simple guide.

#### Event Title
This must be a brief description of your event. Something like “5x5 football in Chalandri”, “Secret location beach party”, or “Chill meetup in Agia Paraskevi square”.


#### Describe your Event
Now it’s your chance to describe your event. Include any detail you would have wanted to see at an event like yours, created by someone else. Anything important, you should include here. Trust us, if you are analytical, then more people and more suitable people will show interest in your event.

#### Private Event
All custom events are open by default, meaning that all users of Join can view and interact with your event, except people you’ve blocked. If you switch your event to private, then access will be limited to only your friends and people you invite. You can alter your event privacy even after its creation.

#### Date and Time
Here you are choosing the date and time your event will begin at. Don’t get confused with a.m. times: For example, Sunday, 00:45 may still be Saturday evening in your mind, but on Join it’s Sunday morning and that’s how you need to list it. 

#### Category
We concluded that your event will most likely fall under one of these categories: Sports, Party, Travel, or Meetup. If you cannot clearly see your event belonging in any of these categories, please pick “meetup”. After all, an event can’t not be a meetup.

#### Invite your Friends
This is the step where you initially invite your friends (don’t worry, you can invite more afterwards). Invited members will receive an invitation notification from you, which they must affirmatively answer if they are to join the event. You can create an event without inviting anybody and have people request to join you. 

Your custom event is now ready for creation !`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question: "Can I create a private group or join a group privately?",
      answer: `
All groups at Places and Verified Events are public. Despite that, you may change your profile to private, which limits your appearance in the groups to only your friends and joined members of that specific group.`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question: "Can I create a private event or join an event privately?",
      answer: `
In contrast to groups at Places and Verified Events, you can create or join private Custom Events. If you are the creator of a custom event, you may at all times switch your event from open to private and vice versa.`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Am I making a reservation when creating a group?",
      answer: `
At the moment, Join does not make table reservations for your groups.`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "I am hesitant to join and create groups on Join, as other people will then know about my real life – social life",
      answer: `
We deeply understand your concern related to your privacy and try our best to meet your privacy needs, such as by offering you the ability to restrict your profile exposure via the App’s settings or to create private events, while keeping Join functional, being an App that has bringing people together in real life at its core.
  `,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Can I invite friends to someone else’s event?",
      answer: `
Yes, you can. Only if it’s a private event, its creator will receive a notification of your intent to invite someone to their event and must approve it for the invitation to go through. In open events, just like in groups, any joined member can freely invite their friends without engaging the creator.`,
    },
    {
      id: 8,
      categories: [],
      question:
        "I have blocked someone on Join. Do they get to see the groups and events that I join?",
      answer: `
People that you’ve blocked on Join cannot see if you’ve joined, created, or gotten invited to a group or an event, unless they relate to that group or event as well. `,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "What am I seeing in the 'Hot Page'?",
      answer: `
Join’s content is comprised of Groups and Custom Events. Groups can be created under “Places” or under “Verified Events”, whereas Custom Events are standalone entities created and customized by the users themselves. We have created an algorithm that filters groups and custom events according to how well your profile matches with the profiles in them, mixes and presents them accordingly in the Hot Page.`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Why is Join asking for all this profile info?",
      answer: `
We respect your concern regarding your profile privacy, while striving to deliver our sole purpose to our users. That is no other than to bring you, as people, together in real life. To correctly achieve this, we need you to tell us about you, what you like doing in real life, where you are studying or have studied, if you are a student and what type of people you prefer interacting with. `,
    },
    {
      id: 11,
      categories: ["profile"],
      question: "Can I omit to disclose some profile info asked by Join?",
      answer: `
We generally advise our users not to skip on correctly and adequately presenting themselves via their profiles on Join, as this will harm their experience on our platform. Despite that, in many instances, you can choose “None” or “Other” as an answer, in case you don’t want to disclose the information we are asking about you.`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "What is 'Profile Strength'?",
      answer: `
Profile Strength depicts how adequate and strong your profile will be on Join,  based on its current state. It does not depict whether it is complete or not and it is only visible to you.`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "What are these two options?",
      answer: `
At this stage, you are seeing two options.

#### The first one is to create your Custom Event. Click on the -Custom Event- button and create your very own event, be it a party, a sports event, a meetup, or even a trip!.

#### The second option is for you to swipe down below, between Places & Verified Events until you find what you're looking to create your Group in.`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "What is the difference between a 'Custom Event' and a 'Group'?",
      answer: `
A Custom Event is an event created from a user -another member of Join just like you- unlike Verified Events which are simillar to Places, in that they are uploaded by the Join team.

#### Custom Events have their own entity and presence, whereas Groups get listed only under a Place or a Verified Event.`,
    },
  ],
  el: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "Χρειάζομαι βοήθεια για τη δημιουργία του group μου",
      answer: `
Κατ' αρχάς, να ξέρεις ότι όντας ο δημιουργός του δικού σου group, σημαίνει ότι έχεις τον απόλυτο έλεγχο του, οπότε δεν υπάρχει λόγος για άγχος, ακόμη και αν είναι το πρώτο που δημιουργείς. Ακολουθεί ένας απλός οδηγός.

#### Ημερομηνία και Ώρα
Εδώ επιλέγεις την ημερομηνία και ώρα συνάντησης του group. Μην μπερδευτείς με τις πρωινές ώρες: Για παράδειγμα, η Κυριακή, 00:45 μπορεί να είναι ακόμα Σάββατο βράδυ στο μυαλό σου, αλλά στο Join είναι Κυριακή πρωί και έτσι πρέπει να το καταχωρήσεις.

#### Προσκάλεσε Φίλους
Αυτό είναι το βήμα στο οποίο προσκαλείς τους φίλους σου (μην ανησυχείς, μπορείς να προσκαλέσεις κι άλλους στη συνέχεια). Τα προσκεκλημένα μέλη θα λάβουν μια ειδοποίηση πρόσκλησης από εσένα, στην οποία θα πρέπει να απαντήσουν καταφατικά αν θέλουν να γίνουν μέλη του group. Μπορείς να δημιουργήσεις ένα group χωρίς να προσκαλέσεις κανέναν περιμένοντας κόσμο να σου στείλει join request.

#### Κάνε μία Δήλωση
Αυτή είναι η ευκαιρία σου να προσθέσεις ένα στοιχείο προσωπικής πινελιάς. Αυτό μπορεί να είναι κάτι σαν "Γιορτάζουμε τα γενέθλιά μου" ή "Κορίτσια φορέστε λευκό φόρεμα".

Το group σου είναι τώρα έτοιμo για δημιουργία!
      `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "Χρειάζομαι βοήθεια για τη δημιουργία του event μου.",
      answer: `
Κατ' αρχάς, να ξέρεις ότι όντας ο δημιουργός του δικού σου event σημαίνει ότι έχεις τον απόλυτο έλεγχο του, οπότε δεν υπάρχει λόγος να αγχώνεσαι, ακόμη και αν είναι το πρώτο event που δημιουργείς. Ακολουθεί ένας απλός οδηγός.",

#### Τίτλος του Event
Αυτή πρέπει να είναι μια σύντομη περιγραφή του event σου. Κάτι σαν "Ποδόσφαιρο 5χ5 στο Χαλάνδρι", "Secret location beach party", ή "Άραγμα στην πλατεία της Αγίας Παρασκευής".


#### Περιέγραψε το Event
Τώρα είναι η ευκαιρία να περιγράψεις αναλυτικά το event σου. Να συμπεριλάβεις κάθε λεπτομέρεια που θα ήθελες να δεις σε ένα event σαν το δικό σου, αναρτημένο από κάποιον άλλον. Οτιδήποτε σημαντικό, θα πρέπει να το συμπεριλάβεις εδώ. Πίστεψέ μας, αν είσαι αναλυτικός, τότε περισσότεροι και πιο κατάλληλοι άνθρωποι θα δείξουν ενδιαφέρον για το event σου.

#### Ιδιωτικό Event
Όλα τα εξατομικευμένα events είναι από προεπιλογή ανοιχτά, πράγμα που σημαίνει ότι όλοι οι χρήστες του Join μπορούν να δουν και να αλληλεπιδράσουν με το event σου, εκτός από τα άτομα που έχεις μπλοκάρει. Αν αλλάξεις το event σου σε ιδιωτικό, τότε η πρόσβαση θα περιοριστεί μόνο στους φίλους σου και στα άτομα που προσκαλείς. Μπορείς να αλλάξεις την ιδιωτικότητα του event σου ακόμα και μετά τη δημιουργία του.

#### Ημερομηνία και ώρα
Εδώ επιλέγεις την ημερομηνία και ώρα που ξεκινάει το event σου. Μην μπερδευτείς με τις πρωινές ώρες: Για παράδειγμα, η Κυριακή, 00:45 μπορεί να είναι ακόμα Σάββατο βράδυ στο μυαλό σου, αλλά στο Join είναι Κυριακή πρωί και έτσι πρέπει να το καταχωρήσεις.

#### Κατηγορία
Καταλήξαμε στο ότι το event σου πιθανότατα θα ανήκει σε μία από αυτές τις κατηγορίες: Αθλήματα, Πάρτι, Ταξίδια ή Συναντήσεις. Εάν δεν μπορείς να διακρίνεις ξεκάθαρα την κατηγορία του event σου, παρακαλούμε να επιλέξεις την «Συνάντηση». Όπως και να έχει, το event σου δεν γίνεται να μην είναι μια συνάντηση.

#### Προσκάλεσε Φίλους
Εδώ επιλέγεις την ημερομηνία και ώρα που ξεκινάει το event σου. Μην μπερδευτείς με τις πρωινές ώρες: Για παράδειγμα, η Κυριακή, 00:45 μπορεί να είναι ακόμα Σάββατο βράδυ στο μυαλό σου, αλλά στο Join είναι Κυριακή πρωί και έτσι πρέπει να το καταχωρήσεις.

Το event σου είναι έτοιμο για δημιουργία!`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "Μπορώ να δημιουργήσω ένα ιδιωτικό group ή να κάνω join ένα group ιδιωτικά;",
      answer: `
Όλα τα group στα Μαγαζιά και στα Επιλεγμένα Events είναι δημόσια. Παρόλα αυτά, μπορείς να αλλάξεις το προφίλ σου σε ιδιωτικό, πράγμα που περιορίζει την εμφάνισή σου στις ομάδες μόνο στους φίλους σου και στα ενταγμένα μέλη της συγκεκριμένης ομάδας.`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "Μπορώ να δημιουργήσω ένα ιδιωτικό event η να κάνω join ένα event ιδιωτικά;",
      answer: `
Σε αντίθεση με τα groups στα Μαγαζιά και τα Επιλεγμένα Events, μπορείς να δημιουργήσεις ή να ενταχθείς σε ιδιωτικά Εξατομικευμένα Εvents. Εάν είσαι ο δημιουργός ενός εξατομικευμένου event, μπορείς ανά πάσα στιγμή να το αλλάξεις από ανοιχτό σε ιδιωτικό και αντίστροφα.`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Κάνω κράτηση όταν δημιουργώ ένα group;",
      answer: `
Προς το παρόν, το Join δεν κάνει κρατήσεις τραπεζιών για τα group σου.`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "Διστάζω να κάνω join και να δημιουργήσω groups στο Join, καθώς οι άλλοι άνθρωποι θα γνωρίζουν τότε για την πραγματική, κοινωνική μου ζωή",
      answer: `
Κατανοούμε βαθύτατα την ανησυχία σου σχετικά με την ιδιωτική σου ζωή και προσπαθούμε να κάνουμε ό,τι καλύτερο μπορούμε για να ικανοποιήσουμε τις ανάγκες σου σε θέματα ιδιωτικότητας, όπως προσφέροντάς σου τη δυνατότητα να περιορίσεις την έκθεση του προφίλ σου μέσω των ρυθμίσεων της εφαρμογής ή να δημιουργήσεις ιδιωτικά Events, διατηρώντας παράλληλα το Join λειτουργικό, καθώς πρόκειται για μια Εφαρμογή που έχει στον πυρήνα της το να φέρνει τους ανθρώπους κοντά στην πραγματική ζωή.
  `,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Μπορώ να προσκαλέσω φίλους μου στο event κάποιου άλλου;",
      answer: `
Ναι, μπορείς. Μόνο αν πρόκειται για ιδιωτικό event, ο δημιουργός του θα λάβει ειδοποίηση για την πρόθεσή σου να προσκαλέσεις κάποιον στο event του και θα πρέπει να το εγκρίνει για να περάσει η πρόσκληση. Στα ανοιχτά events, όπως και στα groups, κάθε μέλος που έχει ενταχθεί, μπορεί ελεύθερα να προσκαλέσει τους φίλους του χωρίς να εμπλέξει κάπως τον δημιουργό.`,
    },
    {
      id: 8,
      categories: [],
      question:
        "Έχω μπλοκάρει κάποιον στο Join. Μπορεί να δει τα groups και τα events στα οποία συμμετέχω;",
      answer: `
Τα άτομα που έχετε μπλοκάρει στο Join δεν μπορούν να δουν αν έχετε συμμετάσχει, δημιουργήσει ή προσκληθεί σε ένα group ή ένα event, εκτός αν σχετίζονται και αυτά με το εν λόγω group ή event.`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "Τι βλέπω στην 'Hot Page';",
      answer: `
Το περιεχόμενο του Join αποτελείται από Groups και Εξατομικευμένα Events. Τα Groups μπορούν να δημιουργηθούν στο πλαίσιο των "Μαγαζιών" ή στο πλαίσιο των "Επιλεγμένων Events", ενώ τα εξατομικευμένα Events είναι αυτόνομες οντότητες που δημιουργούνται και προσαρμόζονται από τους ίδιους τους χρήστες. Έχουμε δημιουργήσει έναν αλγόριθμο που φιλτράρει τα Groups και τα εξατομικευμένα events ανάλογα με το πόσο καλά ταιριάζει το προφίλ σου με τα προφίλ σε αυτά, τα αναμειγνύει και τα παρουσιάζει αναλόγως στη Hot Page.`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Γιατί το Join ζητάει όλες αυτές τις πληροφορίες προφίλ;",
      answer: `
Σεβόμαστε την ανησυχία σου σχετικά με το απόρρητο του προφίλ σου, ενώ παράλληλα προσπαθούμε να προσφέρουμε τον μοναδικό μας σκοπό στους χρήστες μας. Αυτός δεν είναι άλλος από το να σας φέρουμε, ως ανθρώπους, κοντά στην πραγματική ζωή. Για να το πετύχουμε σωστά αυτό, πρέπει να μας πεις για σένα, τι σου αρέσει να κάνεις στην πραγματική ζωή, πού σπουδάζεις ή έχεις σπουδάσει, αν είσαι φοιτητής και με τι τύπου ανθρώπους προτιμάς να συναναστρέφεσαι.`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "Μπορώ να παραλείψω να αποκαλύψω κάποιες πληροφορίες προφίλ που ζητούνται από το Join;",
      answer: `
      Γενικά συμβουλεύουμε τους χρήστες μας να μην παραλείπουν να παρουσιάζουν σωστά και επαρκώς τον εαυτό τους μέσω του προφίλ τους στο Join, καθώς αυτό θα βλάψει την εμπειρία τους στην πλατφόρμα. Παρά ταύτα, σε πολλές περιπτώσεις, μπορείς να επιλέξεις ως απάντηση το «Κανένα» ή το «Άλλο», σε περίπτωση που δεν θέλεις να αποκαλύψεις τις πληροφορίες που σου ζητάμε.`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "Τι είναι το 'Profile Strength';",
      answer: `
Το Profile Strength απεικονίζει πόσο επαρκές και ισχυρό θα είναι το προφίλ σου στο Join, με βάση την τρέχουσα κατάστασή του. Δεν απεικονίζει αν είναι πλήρες ή όχι και είναι ορατό μόνο σε εσένα.`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "Τι είναι αυτές οι δύο επιλογές;",
      answer: `
Σε αυτό το στάδιο, βλέπεις δύο επιλογές.

#### Η πρώτη επιλογή είναι να δημιουργήσεις το δικο σου 'Custom Event'. Κάνε κλικ στο κουμπί -Το δικό σου Event-, και δημιουρήσε το event σου που μπορέι να είναι ένα πάρτυ, ένα αθλητικό event, μία συνάντηση ή ακόμα και ένα ταξίδι.

#### Η δεύτερη επιλογή είναι να σύρεις μεταξύ των ΜΑγαζίων και των Επιλεγμένων Events, μέχρι να βρεις αυτό στο οποίο θέλεις να δημιουργήσεις το Group σου.`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "Ποία ειναι η διαφορά μεταξύ ενός 'Εξατομικευμένου Event' και ενός 'Group'",
      answer: `
Ένα Εξατομικευμένο Event είναι ένα Event που δημιουργήθηκε από έναν χρήστη -ένα άλλο μέλος του Join όπως εσύ- σε αντίθεση με τα Επιλεγμένα Events, που είναι παρόμοια με τα Μαγαζία, καθώς αναρτώνται από την ομάδα του Join.

#### Τα Εξατομικευμένα Events έχουν τη δική τους οντότητα και παρουσία, ενώ τα Groups εμφανίζονται μόνο κάτω από ένα Μαγαζί ή ένα Επιλεγμένο Event.`,
    },
  ],
};

export { getFaqs };

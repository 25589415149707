import React from "react";

function CookiePolicyGreek() {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:"Noto Sans Symbols";\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-size:11.0pt;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n',
        }}
      />
      <div className="WordSection1">
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "24.0pt", lineHeight: "130%" }}>
              Πολιτική Cookie
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Τελευταία τροποποίηση στις 9 Απριλίου 2024
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Αυτή η πολιτική μπορεί να αλλάξει με την πάροδο του χρόνου. Όποτε
            έχουμε προγραμματίσει να εφαρμόσουμε ουσιαστικές αλλαγές σε αυτήν
            την πολιτική, θα σας ειδοποιούμε εγκαίρως για τις αλλαγές.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Δεν υπάρχουν βασικές αλλαγές σε αυτήν την έκδοση.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Διαβάζετε την πιο πρόσφατη έκδοση της Πολιτικής Cookie του Join.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Παρακαλούμε, λάβετε υπόψη ότι αυτή η Πολιτική Cookies εξηγεί
            αποκλειστικά τον τρόπο με τον οποίο χρησιμοποιούμε τα Cookies και
            δεν αναφέρεται στον τρόπο με τον οποίο επεξεργαζόμαστε τα δεδομένα
            σας. Παρακαλούμε, διαβάστε την{" "}
          </span>
          <span lang="el">
            <a href="https://join-socialapp.com/gr/privacy_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Πολιτική Απορρήτου
              </span>
            </a>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            μας εάν θέλετε να μάθετε πώς επεξεργαζόμαστε τα προσωπικά σας
            στοιχεία.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              1. Σχετικά με τα Cookie και Παρόμοιες Τεχνολογίες
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Τα cookies, ως μικρά αρχεία κειμένου, μας επιτρέπουν, μαζί με τους
            παρόχους υπηρεσιών και τους συνεργάτες μας, να αναγνωρίζουμε τη
            συσκευή ή το πρόγραμμα περιήγησής σας για τους διάφορους σκοπούς που
            περιγράφονται στην παρακάτω παράγραφο. Συνήθως, τα cookies αποδίδουν
            έναν μοναδικό αριθμό στη συσκευή σας και αποθηκεύονται στο πρόγραμμα
            περιήγησής σας από τους ιστότοπους που επισκέπτεστε, καθώς και από
            τρίτους παρόχους υπηρεσιών και συνεργάτες που σχετίζονται με αυτούς
            τους ιστότοπους.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Cookies Περιόδου Λειτουργίας:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            Αυτά τα cookies ενδέχεται να παραμείνουν στον υπολογιστή ή την
            κινητή συσκευή σας για διαφορετική διάρκεια. Ορισμένα ορίζονται ως
            "cookies περιόδου λειτουργίας", τα όποια είναι ενεργά μόνο όσο το
            πρόγραμμα περιήγησής σας είναι ανοιχτό και διαγράφονται αυτόματα
            κατά το κλείσιμό του. Τα cookies περιόδου λειτουργίας δεν έχουν την
            ικανότητα να παρακολουθούν το πρόγραμμα περιήγησης ή τη συσκευή σας
            σε διαφορετικούς ιστότοπους.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Διαρκή Cookies:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            Αντιθέτως, τα "διαρκή cookies" διαρκούν εως και μετά το κλείσιμο του
            προγράμματος περιήγησής σας, συχνά προγραμματισμένα να λήγουν
            αυτόματα μετά από ένα καθορισμένο χρονικό διάστημα (π.χ. ημέρες,
            εβδομάδες ή μήνες). Κατά την επιστροφή σε έναν ιστότοπο ή την
            επίσκεψη σε άλλους που χρησιμοποιούν τα ίδια cookies, ο πάροχος του
            ιστότοπου ή οι πάροχοι υπηρεσιών του μπορούν να αναγνωρίσουν τα
            διαρκή cookies που έχουν οριστεί, αναγνωρίζοντας έτσι τη συσκευή
            περιήγησής σας.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Διασύνδεση Προγραμματισμού Εφαρμογών (API):
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            Ένα API χρησιμεύει ως λογισμικό που διευκολύνει την επικοινωνία
            μεταξύ δύο ή περισσότερων εφαρμογών, και εμείς τα χρησιμοποιούμε για
            τη δημιουργία επικοινωνίας μεταξύ του Join και των παρόχων υπηρεσιών
            μας.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Κιτ ανάπτυξης λογισμικού (SDK):
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            Τα SDKs αποτελούνται από τμήματα κώδικα που επιτρέπουν τη συλλογή
            δεδομένων σχετικά με τη συσκευή σας, το δίκτυο και τις
            αλληλεπιδράσεις σας με έναν ιστότοπο. Ομοίως με τα cookies, γενικά
            λειτουργούν με την εκχώρηση ενός μοναδικού αριθμού στη συσκευή σας.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              2. Γιατί και Πως χρησιμοποιούμε Cookies
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Τα cookies μας βοηθούν, όπως βοηθούν και άλλους παρόχους
            διαδικτυακών υπηρεσιών, να προστατεύουμε, να παρέχουμε και να
            βελτιώνουμε τις υπηρεσίες που σας προσφέρουμε. Αυτό ενδέχεται να
            πραγματοποιείται με διάφορους τρόπους όπως
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            με το να έχουμε πρόσβαση σε πληροφορίες σχετικές με τις προτιμήσεις
            σας για τις επόμενες επισκέψεις σας{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            παρέχοντας αναλυτικά στοιχεία (analytics) και δεδομένα που είναι
            σημαντικά για την κατανόηση του τρόπου με τον οποίο οι χρήστες μας
            χρησιμοποιούν την Εφαρμογή μας, μετρώντας ποια μέρη της Υπηρεσίας
            απολαμβάνουν να χρησιμοποιούν και ποια όχι, παρέχοντάς μας έτσι
            σημαντικά δεδομένα που μας υποδεικνύουν πώς να βελτιώσουμε τις
            υπηρεσίες που σας παρέχουμε
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: "36.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            παρέχοντας βοήθεια σχετικά με τον έλεγχο ταυτοποίησης, π.χ. σε
            θέματα όπως η επαλήθευση του λογαριασμού σας και ο προσδιορισμός του
            πότε είστε συνδεδεμένοι{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            την υποβοήθηση της ασφάλειας και της ακεραιότητας της υπηρεσίας.
            Αυτό θα επιτευχθεί βοηθώντας μας να παρακολουθούμε ανησυχητική ή
            ύποπτη συμπεριφορά, σε περίπτωση που για παράδειγμα κάποιος
            προσπαθεί να αποκτήσει πρόσβαση σε έναν λογαριασμό προσπαθώντας να
            μαντέψει τον κωδικό πρόσβασής του ή βοηθώντας μας να επαναφέρουμε
            τον λογαριασμό σας σε περίπτωση που έχετε ξεχάσει τον κωδικό
            πρόσβασής σας.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: "36.0pt", lineHeight: "130%" }}
        >
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              3. Συνεργάτες που χρησιμοποιούν Cookies στο Join και η Χρησιμότητα
              τους
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "2.0pt" }}>
          <b>
            <u>
              <span
                lang="el"
                style={{ fontSize: "15.0pt", lineHeight: "115%" }}
              >
                Αναλυτικά Δεδομένα
              </span>
            </u>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
              Google Analytics για Firebase
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
              Κατηγορίες Δεδομένων:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            &nbsp; Διακριτικά συσκευής για την συλλογή και αποθήκευση
            πληροφοριών όπως ο αριθμός περιόδων λειτουργίας του χρήστη, οι
            αρχικές εκκινήσεις εφαρμογής, πληροφορίες της περιόδου λειτουργίας,
            πλοήγηση, δραστηριότητα, το λογισμικό και μοντέλο της συσκεής,
            πληροφορίες τοποθεσίας, και πληροφορίες για ενημερώσεις της
            εφαρμογής.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Πολιτική Απορρήτου:{" "}
          </span>
          <span lang="el">
            <a href="https://policies.google.com/technologies/partner-sites">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://policies.google.com/technologies/partner-sites
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="el"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="el"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "2.0pt" }}>
          <b>
            <u>
              <span
                lang="el"
                style={{ fontSize: "15.0pt", lineHeight: "115%" }}
              >
                Σύνδεση &amp; Ταυτοποίηση
              </span>
            </u>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
              Apple
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
              Κατηγορίες Δεδομένων:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Σύνδεση και Ταυτοποίηση
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Πολιτική Απορρήτου:{" "}
          </span>
          <span lang="el">
            <a href="https://www.apple.com/legal/privacy/en-ww/">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://www.apple.com/legal/privacy/en-ww/
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
              Facebook
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
              Κατηγορίες Δεδομένων:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Σύνδεση και Ταυτοποίηση
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Πολιτική Απορρήτου:
          </span>
          <span lang="el">
            <a href="https://en-gb.facebook.com/policy.php">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "windowtext",
                  textDecoration: "none",
                }}
              ></span>
            </a>
            <a href="https://en-gb.facebook.com/policy.php">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://en-gb.facebook.com/policy.php
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
              Google
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
              Κατηγορίες Δεδομένων:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Σύνδεση και Ταυτοποίηση
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Πολιτική Απορρήτου:
          </span>
          <span lang="el">
            <a href="https://policies.google.com/privacy?hl=en-US">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "windowtext",
                  textDecoration: "none",
                }}
              >
                {" "}
              </span>
            </a>
            <a href="https://policies.google.com/privacy?hl=en-US">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://policies.google.com/privacy?hl=en-US
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal">
          <u>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                color: "#1155CC",
              }}
            >
              <span style={{ textDecoration: "none" }}>&nbsp;</span>
            </span>
          </u>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "2.0pt" }}>
          <b>
            <u>
              <span
                lang="el"
                style={{ fontSize: "15.0pt", lineHeight: "115%" }}
              >
                Μηνύματα
              </span>
            </u>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
              Firebase
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
              Κατηγορίες Δεδομένων:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Αρχεία καταγραφής μηνυμάτων και αναγνωριστικά
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
            Privacy Policy:
          </span>
          <span lang="el">
            <a href="https://firebase.google.com/support/privacy">
              <span
                lang="EN-US"
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "115%",
                  color: "#1155CC",
                }}
              >
                https://firebase.google.com/support/privacy
              </span>
            </a>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              4. Τρόποι ελέγχου των cookies
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              Εξαίρεση από τους συνεργάτες:
            </span>
          </b>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            Πολλοί συνεργάτες με τους οποίους συνεργαζόμαστε προσφέρουν επιλογές
            εξαίρεσης στις ιστοσελίδες τους. Λεπτομέρειες σχετικά με αυτό
            μπορείτε να βρείτε στις πολιτικές τους, οι οποίες είναι προσβάσιμες
            μέσω της παραπάνω λίστας συνεργατών Cookies.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Όπως αναφέρθηκε προηγουμένως, ορισμένες εφαρμογές αυτών των
            τεχνολογιών είναι ζωτικής σημασίας για την ορθή λειτουργία της
            Πλατφόρμας, καθιστώντας τις μη απενεργοποιήσιμες.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              5. Γενικά
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Μπορείτε να εξαιρεθείτε από την προβολή διαφημίσεων βάσει του
            ενδιαφέροντος σας στο διαδίκτυο, μεταβαίνοντας στους παρακάτω
            συνδέσμους:
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el">
            <a href="http://www.aboutads.info/choices/">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "blue",
                }}
              >
                Digital Advertising Alliance
              </span>
            </a>
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            &nbsp;στις ΗΠΑ
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el">
            <a href="http://youradchoices.ca/">
              <span
                lang="EN-US"
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "blue",
                }}
              >
                Digital Advertising Alliance of Canada
              </span>
            </a>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            &nbsp;
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            στον
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            {" "}
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            Καναδά
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="el">
            <a href="http://www.youronlinechoices.eu/">
              <span
                lang="EN-US"
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "blue",
                }}
              >
                European Interactive Digital Advertising Alliance
              </span>
            </a>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            &nbsp;
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            στην
          </span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          ></span>
          <span
            lang="el"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              color: "#1C1E21",
              background: "white",
            }}
          >
            Ευρώπη
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Ή μέσω πρόσβασης στις ρυθμίσεις της συσκευής σας που σχετίζονται με
            τα cookies.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <b>
            <span lang="el" style={{ fontSize: "18.0pt", lineHeight: "130%" }}>
              6. Πώς να επικοινωνήσετε μαζί μας
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: "130%" }}>
          <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Εάν έχετε οποιεσδήποτε ερωτήσεις σχετικά με αυτήν την Πολιτική
            Cookie, χρησιμοποιήστε αυτήν{" "}
          </span>
          <span lang="el">
            <a href="https://join-socialapp.com/contact">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                τη φόρμα επικοινωνίας
              </span>
            </a>
          </span>
          <b>
            <span lang="el" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
              .
            </span>
          </b>
        </p>
        <p className="MsoNormal">
          <span lang="el">&nbsp;</span>
        </p>
      </div>
    </>
  );
}

export default CookiePolicyGreek;

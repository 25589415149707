import React, { useState } from "react";
import logo from "../../assets/joinlogo.png";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import platform from "platform";
import Countdown from "react-countdown";
import Box from "@mui/material/Box";
import "../../assets/CountdownTimer.css";
import "../../assets/App.css";
import { useTranslation } from "react-i18next";

export default function FirstSection() {
  const { t, i18n } = useTranslation();

  const os = platform.os.family;

  const padWithZeros = (number, minLength) => {
    const numberString = number.toString();
    return numberString.length >= minLength
      ? numberString
      : "0".repeat(minLength - numberString.length) + numberString;
  };

  const [isCountdownFinished, setIsCountdownFinished] = useState(false);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCountdownFinished(true);
      // Render a completed state
      return !["Android", "iOS"].includes(os) ? (
        <Stack spacing={1} justifyContent="center" direction="row">
          <img
            src="./ios.svg"
            alt="app_store"
            onClick={() => console.log("appstore")}
            style={{ width: 160 }}
          />
          <img
            src="./android.png"
            alt="google_play"
            onClick={() => console.log("playstore")}
            style={{ width: 200 }}
          />
        </Stack>
      ) : (
        <img
          src={os.toLowerCase() === "android" ? "./android.png" : "ios.svg"}
          alt="google_play"
          onClick={() => console.log("playstore")}
          style={{ width: 200 }}
        />
      );
    } else {
      // Render a countdown
      return (
        <Stack spacing={4} justifyContent="center" direction="row">
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(days, 2)}</span>
            <span className="label">{t("Days")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(hours, 2)}</span>
            <span className="label">{t("Hours")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(minutes, 2)}</span>
            <span className="label">{t("Minutes")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(seconds, 2)}</span>
            <span className="label">{t("Seconds")}</span>
          </Stack>
        </Stack>
      );
    }
  };

  const handleCountdownComplete = () => {
    setIsCountdownFinished(true);
  };

  return (
    <Stack
      spacing={2}
      alignItems="center"
      marginTop={{ xs: 15, sm: 25 }}
      paddingInline={4}
      sx={{
        background: "linear-gradient(to bottom, white, lightblue)", // Add this line for the gradient
        padding: 4,
        borderRadius: 2,
      }}
    >
      <img
        className="App-logo"
        src={logo}
        alt="Join Trademark - Mobile app for socializing, joining events, meeting people, and dating"
        title="Trademark of Join - A Mobile App for Real Socializing, Events, and Dating"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: 30, md: 40 },
            fontFamily:
              i18n.language === "el" ? "Manrope Regular" : "Trueno Light",
            letterSpacing: 1,
            color: "#1EABF1",
            textAlign: "center",
          }}
        >
          <pre style={{ fontFamily: "inherit" }}>{t("Slogan")}</pre>
        </Typography>
      </Box>

      <Typography
        variant="body1"
        align="center"
        color="#57606f"
        fontFamily={
          i18n.language === "el" ? "OpenSans MediumItalic" : "Montserrat Italic"
        }
      >
        {isCountdownFinished ? t("AvailableNow") : t("AvailableIn")}
      </Typography>
      <Countdown
        date={new Date(2024, 9, 15)}
        renderer={renderer}
        onComplete={handleCountdownComplete}
      />
    </Stack>
  );
}

import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CustomStyledLink from "./CustomStyledLink";
import Grid from "@mui/material/Grid";

export default function FooterSocial() {
  return (
    <Grid
      container
      justifyContent="center" // Center horizontally
      alignItems="center" // Center vertically
      spacing={2} // Adjust spacing as needed
    >
      <Grid item>
        <CustomStyledLink
          target="_blank"
          href="https://www.facebook.com/profile.php?id=61565505053856&mibextid=LQQJ4d"
          aria-label="facebook"
        >
          <FacebookIcon sx={{ fontSize: 30 }} />
        </CustomStyledLink>
      </Grid>
      <Grid item>
        <CustomStyledLink
          target="_blank"
          href="https://www.instagram.com/join_socialapp?igsh=OWc2emsydWVvMDZh&utm_source=qr"
          aria-label="instagram"
        >
          <InstagramIcon sx={{ fontSize: 30 }} />
        </CustomStyledLink>
      </Grid>
    </Grid>
  );
}

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Link as MuiLink } from "@mui/material";

// Styled Link component
const StyledLink = styled(MuiLink)({
  textTransform: "none",
  color: "#57606f",
  boxShadow: "none",
  borderRadius: 13,
  "&:hover": {
    color: "#5FC9F8",
    boxShadow: "none",
  },
});

// Utility function to prepend language prefix
const getLanguagePath = (to, lang) => {
  const validLang = lang === "en" || lang === "el" ? lang : "en"; // Default to "en" if invalid
  return `/${validLang}${to.startsWith("/") ? to : `/${to}`}`;
};

// Custom Styled Link component
const CustomStyledLink = (props) => {
  const { to = "", ...restProps } = props; // Provide default empty string for 'to'
  const storedLang = localStorage.getItem("lang") || "en"; // Use stored language

  // Generate full path with language prefix
  const fullPath = getLanguagePath(to, storedLang);

  return <StyledLink component={RouterLink} to={fullPath} {...restProps} />;
};

export default CustomStyledLink;

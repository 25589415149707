import React from "react";
import "./assets/App.css";
import AppBarHeader from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import AttributionsScreen from "./pages/attributions/AttributionsScreen";
import PrivacyPolicyScreen from "./pages/privacy_policy/PrivacyPolicyScreen";
import CookiePolicyScreen from "./pages/cookie_policy/CookiePolicyScreen";
import ContactScreen from "./pages/contact_us/ContactScreen";
import Terms from "./pages/terms/TermsScreen";
import AboutUsScreen from "./pages/about_us/AboutUsScreen";
import FAQ from "./pages/FAQ";
import ScrollToTop from "./ScrollToTop";
import { Helmet } from "react-helmet";

// Language Routes Component
function LanguageRoutes() {
  const { lang } = useParams(); // Get language from URL params
  const validLang = lang === "en" || lang === "el" ? lang : "en"; // Default language

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/attributions" element={<AttributionsScreen />} />
      <Route path="/privacy_policy" element={<PrivacyPolicyScreen />} />
      <Route path="/cookie_policy" element={<CookiePolicyScreen />} />
      <Route path="/contact" element={<ContactScreen />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/aboutus/:isReasonExpanded?" element={<AboutUsScreen />} />
      <Route
        path="/faq/group_creation"
        element={<FAQ category={"groupCreation"} />}
      />
      <Route path="/faq/hot_create" element={<FAQ category={"hotCreate"} />} />
      <Route path="/faq/interact" element={<FAQ category={"interact"} />} />
      <Route
        path="/faq/events_more"
        element={<FAQ category={"eventsMore"} />}
      />
      <Route
        path="/faq/event_creation"
        element={<FAQ category={"eventCreation"} />}
      />
      <Route path="/faq/group_page" element={<FAQ category={"groupPage"} />} />
      <Route path="/faq/event_page" element={<FAQ category={"eventPage"} />} />
      <Route path="/faq/profile" element={<FAQ category={"profile"} />} />
      <Route path="/faq" element={<FAQ />} />
      {/* Redirect any unfound paths to the root path with the correct language */}
      <Route path="*" element={<Navigate to={`/${validLang}`} />} />
    </Routes>
  );
}

function App() {
  const storedLang = localStorage.getItem("lang") || "en"; // Get stored language or default to 'en'

  const { lang } = useParams(); // Get language from URL params
  const defaultTitle =
    lang === "el"
      ? "Join | Παρέες, Event, Γνωρημίες"
      : "Join | Groups, Events & People";
  const defaultDescription =
    lang === "el"
      ? "Το Join είναι η εφαρμογή που χρειάζεσαι για αληθινή κοινωνικοποίηση. Βρες παρέες σε μαγαζία, event, πάρτυ και πολλά άλλα. Φτίαξε λογαριασμό και κανόνισε με φίλους με έναν τρόπο που δεν είχες φανταστεί"
      : "Join is a mobile  app that is all about real socializing. Use it to join groups & events like parties and many more. Create an account and date people in a way like never before.";

  return (
    <Router>
      <Helmet>
        <title>{defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
      </Helmet>
      <ScrollToTop />
      <AppBarHeader />
      <Routes>
        <Route path="/" element={<Navigate to={`/${storedLang}`} />} />
        <Route path="/:lang/*" element={<LanguageRoutes />} />
        <Route path="*" element={<Navigate to={`/${storedLang}`} />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

const titles = {
  en: {
    siteTitle: "Join | Groups, Events & People",
    tagline: "Our way of socializing, the real way",
    home: "Join | Groups, Events & People",
    about: "Join | Groups, Events & People",
    contact: "Join | Groups, Events & People",
    privacy: "Join | Groups, Events & People",
    // Add other page titles as needed
  },
  el: {
    siteTitle: "Join | Παρέες, Event, Γνωρημίες",
    tagline: "Αληθινή κοινωνικοποίηση",
    home: "Join | Παρέες, Event, Γνωρημίες",
    about: "Join | Παρέες, Event, Γνωρημίες",
    contact: "Join | Παρέες, Event, Γνωρημίες",
    privacy: "Join | Παρέες, Event, Γνωρημίες",
    // Add other page titles as needed
  },
};

export default titles;

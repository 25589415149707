import React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import CustomStyledLink from "./CustomStyledLink"; 
import { Link as RouterLink } from "react-router-dom";

export default function SideDrawer({ isOpen, setIsOpen }) {
  const { t } = useTranslation();

  const options = [
    { text: t("AboutUs"), destination: "/aboutus" },
    { text: "FAQs", destination: "/faq" },
    { text: t("Terms"), destination: "/terms" },
    { text: t("PrivacyPolicy"), destination: "/privacy_policy" },
    { text: t("ContactUs"), destination: "/contact" },
  ];

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={() => setIsOpen(false)}
        onKeyDown={() => setIsOpen(false)}
      >
        <List>
          {options.map((option, _) => (
            <ListItem key={option.text} disablePadding>
              <CustomStyledLink
                component={RouterLink}
                to={option.destination}
                underline="none"
                sx={{
                  fontSize: { xs: 14, sm: 16 },
                  width: '100%',
                  textDecoration: 'none',
                }}
              >
                <Box
                  sx={{
                    padding: "10px 16px", 
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <ListItemText
                    primary={
                      <Box fontFamily="OpenSans SemiBold" color="#57606f">
                        {option.text}
                      </Box>
                    }
                  />
                </Box>
              </CustomStyledLink>
            </ListItem>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GB, GR } from "country-flag-icons/react/3x2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import { StyledIconButton } from "./StyledComponents";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";

export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const navigate = useNavigate(); // Use navigate for routing

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lang", language); // Store language in local storage

    // Redirect to the current path with the new language
    const path = window.location.pathname.split("/").slice(2).join("/");
    navigate(`/${language}/${path}`);
    setAnchorEl(null);
  };

  return (
    <>
      <StyledIconButton
        aria-label="languages"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <LanguageIcon />
      </StyledIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            width: 180,
          },
        }}
      >
        <MenuItem
          onClick={() => selectLanguage("en")}
          sx={{ color: "#57606f" }}
          selected={i18n.language === "en"}
        >
          <ListItemIcon style={{ marginRight: "20px" }}>
            <GB title="English" style={{ width: 30 }} />
          </ListItemIcon>
          English
        </MenuItem>
        <MenuItem
          onClick={() => selectLanguage("el")}
          sx={{ color: "#57606f" }}
          selected={i18n.language === "el"}
        >
          <ListItemIcon style={{ marginRight: "20px" }}>
            <GR title="Greek" style={{ width: 30 }} />
          </ListItemIcon>
          Ελληνικά
        </MenuItem>
      </Menu>
    </>
  );
}
